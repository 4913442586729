import React from 'react';
import ErrorForm from "../../../components/ErrorForm";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import UserTabs from "../../../examples/UserTabs";

const UserErrors = () => {
    return (
        <>


                    <MDBox pt={6} pb={3}>

                        <Grid container>
                            <ErrorForm placeholder={'ошибка 1'}/>
                            <ErrorForm placeholder={'ошибка 1'}/>
                            <ErrorForm placeholder={'ошибка 1'}/>
                            <ErrorForm placeholder={'ошибка 1'}/>

                        </Grid>
                    </MDBox>
        </>
    );
};

export default UserErrors;