/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material DashboardUser 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material DashboardUser 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

export default function tddata() {
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    return {
        columns: [
            { Header: "Криптовалюта", accessor: "crypto", width: "25%", align: "left" },
            { Header: "Сеть", accessor: "net", width: "45%", align: "left" },
            { Header: "Тип", accessor: "type", align: "center" },
            { Header: "Хэш", accessor: "hash", align: "center" },
            { Header: "Время", accessor: "date", align: "center" },


        ],

        rows: [
            {
                crypto: <MDTypography>dffdfdfdfdfd</MDTypography>,
                net: <MDTypography>dffdfdfdfdfd</MDTypography>,
                type: <MDTypography>dffdfdfdfdfd</MDTypography>,
                hash: <MDTypography>dffdfdfdfdfd</MDTypography>,
                date: <MDTypography>dffdfdfdfdfd</MDTypography>,

            },
            {
                crypto: <MDTypography>dffdfdfdfdfd</MDTypography>,
                net: <MDTypography>dffdfdfdfdfd</MDTypography>,
                type: <MDTypography>dffdfdfdfdfd</MDTypography>,
                hash: <MDTypography>dffdfdfdfdfd</MDTypography>,
                date: <MDTypography>dffdfdfdfdfd</MDTypography>,

            },
            {
                crypto: <MDTypography>dffdfdfdfdfd</MDTypography>,
                net: <MDTypography>dffdfdfdfdfd</MDTypography>,
                type: <MDTypography>dffdfdfdfdfd</MDTypography>,
                hash: <MDTypography>dffdfdfdfdfd</MDTypography>,
                date: <MDTypography>dffdfdfdfdfd</MDTypography>,

            },
            {
                crypto: <MDTypography>dffdfdfdfdfd</MDTypography>,
                net: <MDTypography>dffdfdfdfdfd</MDTypography>,
                type: <MDTypography>dffdfdfdfdfd</MDTypography>,
                hash: <MDTypography>dffdfdfdfdfd</MDTypography>,
                date: <MDTypography>dffdfdfdfdfd</MDTypography>,

            },
            {
                crypto: <MDTypography>dffdfdfdfdfd</MDTypography>,
                net: <MDTypography>dffdfdfdfdfd</MDTypography>,
                type: <MDTypography>dffdfdfdfdfd</MDTypography>,
                hash: <MDTypography>dffdfdfdfdfd</MDTypography>,
                date: <MDTypography>dffdfdfdfdfd</MDTypography>,

            },

        ],
    };
}
