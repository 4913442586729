import React from 'react';
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Header from "../../profile/components/Header";
import Grid from "@mui/material/Grid";
import PlatformSettings from "../../profile/components/PlatformSettings";
import Divider from "@mui/material/Divider";
import ProfileInfoCard from "../../../examples/Cards/InfoCards/ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfilesList from "../../../examples/Lists/ProfilesList";
import profilesListData from "../../profile/data/profilesListData";
import MDTypography from "../../../components/MDTypography";
import DefaultProjectCard from "../../../examples/Cards/ProjectCards/DefaultProjectCard";
import homeDecor1 from "../../../assets/images/home-decor-1.jpg";
import team1 from "../../../assets/images/team-1.jpg";
import team2 from "../../../assets/images/team-2.jpg";
import team3 from "../../../assets/images/team-3.jpg";
import team4 from "../../../assets/images/team-4.jpg";
import homeDecor2 from "../../../assets/images/home-decor-2.jpg";
import homeDecor3 from "../../../assets/images/home-decor-3.jpg";
import homeDecor4 from "../../../assets/images/home-decor-4.jpeg";
import Footer from "../../../examples/Footer";
import UserTabs from "../../../examples/UserTabs";

const UserInfo = () => {
    return (
        <>

            <MDBox mb={2} />
            <Header>

                <MDBox mt={5} mb={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} xl={6}>
                            <PlatformSettings />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                            <ProfileInfoCard
                                title="profile information"
                                description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                                info={{
                                    fullName: "Alec M. Thompson",
                                    mobile: "(44) 123 1234 123",
                                    email: "alecthompson@mail.com",
                                    location: "USA",
                                }}
                                social={[
                                    {
                                        link: "https://www.facebook.com/CreativeTim/",
                                        icon: <FacebookIcon />,
                                        color: "facebook",
                                    },
                                    {
                                        link: "https://twitter.com/creativetim",
                                        icon: <TwitterIcon />,
                                        color: "twitter",
                                    },
                                    {
                                        link: "https://www.instagram.com/creativetimofficial/",
                                        icon: <InstagramIcon />,
                                        color: "instagram",
                                    },
                                ]}
                                action={{ route: "", tooltip: "Edit Profile" }}
                                shadow={false}
                            />
                        </Grid>

                    </Grid>
                </MDBox>

            </Header>
        </>

    );
};

export default UserInfo;