import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MDBox from "../../components/MDBox";
import {TabList, TabPanel} from "@mui/lab";
import UserInfo from "../../layouts/user/info";
import {TabContext} from "@mui/lab";
import UserLogs from "../../layouts/user/logs";
import UserTransaction from "../../layouts/user/transaction";
import UserErrors from "../../layouts/user/errors";
import UserOrder from "../../layouts/user/order";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";

export default function UserTabs() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <DashboardLayout>

            <TabContext value={value}>
            <DashboardNavbar />

        <MDBox
                mx={2}
                mt={5}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                    <TabList


                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab label="Главная информация"  value={'info'}/>
                        <Tab label="Логи" value={'logs'} />
                        <Tab label="Транзакции" value={'transaction'} />
                        <Tab label="Ошибки" value={'errors'} />

                        <Tab label="Верификация" />
                        <Tab label="Ордеры" value={'order'}/>
                        <Tab label="Баланс" />
                        <Tab label="Курс Криптовалюты" />

                    </TabList>


            </MDBox>
            <TabPanel value={'info'}><UserInfo/></TabPanel>
            <TabPanel value={'logs'}><UserLogs/></TabPanel>
            <TabPanel value={'transaction'}><UserTransaction/></TabPanel>
            <TabPanel value={'errors'}><UserErrors/></TabPanel>
            <TabPanel value={'order'}><UserOrder/></TabPanel>
        </TabContext>
            </DashboardLayout>

        </>
    );
}