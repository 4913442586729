import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import team2 from "../../../assets/images/team-2.jpg";
import MDBadge from "../../../components/MDBadge";
import team3 from "../../../assets/images/team-3.jpg";
import team4 from "../../../assets/images/team-4.jpg";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import btc from 'assets/images/icons/bitcoin-btc-logo.png'
import {Author} from "../../tables/data/authorsTableData";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

export default function data() {




    return {
        columns: [
            { Header: "Криптовалюта", accessor: "author", width: "4%", align: "center" },
            { Header: "Адрес", accessor: "function", width: '80%',align: "left" },
            { Header: "Сохранить", accessor: "status", align: "center" },

        ],

        rows: [
            {
                author: (

                    <img style={{width: '50px', margin: '0 auto'}} src={btc} />
                ),
                function: (
                    <MDBox
                        style={{width: '400%'}}
                    >
                        <MDInput label={'Введите адрес'} style={{width: '100%'}}/>

                    </MDBox>
                ),
                status: (
                    <Button variant={'contained'} ml={-1}>
                       <Icon style={{'color': "white"}}>save_alt</Icon>
                    </Button>
                ),
                employed: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        23/04/18
                    </MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        Edit
                    </MDTypography>
                ),
            },
            {
                author: (

                    <img style={{width: '50px', margin: '0 auto'}} src={btc} />
                ),
                function: (
                    <MDBox
                        style={{width: '400%'}}
                    >
                        <MDInput label={'Введите адрес'} style={{width: '100%'}}/>

                    </MDBox>
                ),
                status: (
                    <Button variant={'contained'} ml={-1}>
                        <Icon style={{'color': "white"}}>save_alt</Icon>
                    </Button>
                ),
                employed: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        23/04/18
                    </MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        Edit
                    </MDTypography>
                ),
            },
            {
                author: (

                    <img style={{width: '50px', margin: '0 auto'}} src={btc} />
                ),
                function: (
                    <MDBox
                        style={{width: '400%'}}
                    >
                        <MDInput label={'Введите адрес'} style={{width: '100%'}}/>

                    </MDBox>
                ),
                status: (
                    <Button variant={'contained'} ml={-1}>
                        <Icon style={{'color': "white"}}>save_alt</Icon>
                    </Button>
                ),
                employed: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        23/04/18
                    </MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        Edit
                    </MDTypography>
                ),
            },


        ],
    };
}