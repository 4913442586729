/**
 =========================================================
 * Material DashboardUser 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material DashboardUser 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material DashboardUser 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import promoDataTable from "./data/promoDataTable";
import {Form} from "react-router-dom";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Button from "@mui/material/Button";
import FormDialog from "./modal";

function Promo() {
    const { columns, rows } = promoDataTable();

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDTypography variant="h6" color="white">
                                            Промокоды
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormDialog/>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Promo;
