import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputLabel, Select} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

export default function FormDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <MDButton  onClick={handleClickOpen}>
                Open form dialog
            </MDButton>
            <Dialog

                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle><MDTypography>Добавить транзакцию</MDTypography></DialogTitle>
                <DialogContent
                    style={{padding: '6%'}}

                >
                    <FormGroup>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={10}
                                label="Криптовалюта"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={10}

                                label="Сеть"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>

                            <Input
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="email"
                                label="Количество"
                                type="number"

                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-helper-label">Тип транзакции</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={10}
                                label="Age"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>

                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} style={{color: 'white', marginRight: '200px'}} onClick={handleClose}>Отменить</Button>
                    <Button variant={'contained'} style={{color: 'white'}} type="submit">Добавить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}