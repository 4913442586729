import React from 'react';
import MDBox from "../MDBox";
import {TextareaAutosize} from "@mui/base";
import Grid from "@mui/material/Grid";
import {Textarea} from "../TextArea";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";

// eslint-disable-next-line react/prop-types
const ErrorForm = ({placeholder}) => {
    return (
        <>
            <Grid item xs={6}>

                <MDBox
                    mx={2}
                    mt={4}

                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography color={'white'} variant="h5" style={{padding: '2%'}}>
                        {placeholder}
                    </MDTypography>
                    <Textarea minRows={5} placeholer={placeholder} style={{maxWidth: '100%', width: '100%'}}/>
                    <MDButton>
                        Cохранить
                    </MDButton>
                </MDBox>
            </Grid>


        </>
    );
};

export default ErrorForm;