import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";

export default function FormDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle><MDTypography>Добавить доме</MDTypography></DialogTitle>
                <DialogContent
                >

                    <MDInput
                        style={{width: '100%'}}
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Домен"
                        type="email"

                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} style={{color: 'white', marginRight: '200px'}} onClick={handleClose}>Отменить</Button>
                    <Button variant={'contained'} style={{color: 'white'}} type="submit">Добавить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}