import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import MDBadge from "../../../components/MDBadge";
import MDTypography from "../../../components/MDTypography";
import team2 from "../../../assets/images/team-2.jpg";
import {Author} from "../../tables/data/authorsTableData";
import MDButton from "../../../components/MDButton";
import {navbar} from "../../../examples/Navbars/DashboardNavbar/styles";
import Card from "@mui/material/Card";

const useStyles = makeStyles({

    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },


    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

const Chat = () => {
    const classes = useStyles();

    return (

                <Grid container  component={Card} className={classes.chatSection}>

                    <Grid
                                item xs={3} className={classes.borderRight500}>

                        <Grid item xs={12} style={{padding: '10px'}}>
                            <MDInput id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
                        </Grid>

                            <List >

                                    <ListItem button key="RemySharp">

                                        <Author image={team2} name="John Michael" email="john@creative-tim.com" />
                                        <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                                    </ListItem>


                                <ListItem button key="RemySharp">

                                    <Author  image={team2} name="John Michael" email="john@creative-tim.com" />
                                    <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                                </ListItem>

                            </List>
                    </Grid>
                    <Grid item xs={9}>
                            <MDBox className={classes.messageArea}>
                                <ListItem key="1">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <MDTypography align="right" primary="Hey man, What's up ?"></MDTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDTypography align="right" secondary="09:30"></MDTypography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <ListItem key="2">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <MDBox
                                                mt={-1}
                                                py={1}
                                                px={1}
                                                variant="gradient"
                                                bgColor="info"
                                                borderRadius="lg"
                                                coloredShadow="info"
                                            >
                                                <MDTypography align="left" color={'white'} primary="Hey, Iam Good! What about you ?">Hey, Iam Good! What about you ?</MDTypography>

                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDTypography style={{fontSize: '13px'}} align="left" secondary="09:31">12:00</MDTypography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <ListItem key="3">
                                    <Grid container>
                                        <Grid align="right" item xs={12}>
                                            <MDBox
                                                mt={-1}
                                                ml={'50%'}
                                                py={1}
                                                px={1}
                                                variant="gradient"
                                                bgColor="info"
                                                borderRadius="lg"
                                                coloredShadow="info"
                                            >
                                            <MDTypography color={"white"}  align="right" primary="Cool. i am good, let's catch up!">Hey, Iam Good! What about you ?</MDTypography>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDTypography style={{fontSize: '13px'}} align="right" secondary="10:30">10:30</MDTypography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </MDBox>
                        <Divider />
                            <Grid  container style={{paddingBottom: '20px'}} >
                                <Grid item xs={10}>
                                    <MDInput id="outlined-basic-email" label="Type Something" fullWidth />
                                </Grid>
                                <Grid xs={1} align="right">
                                    <MDButton color="info" aria-label="add"><SendIcon /></MDButton>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>

    );
}

export default Chat;